<template>
  <div>
    <!-- 页头 -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/Welcome' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统设置</el-breadcrumb-item>
      <el-breadcrumb-item>字典管理</el-breadcrumb-item>
    </el-breadcrumb>
    <el-card v-if="isShowData">
      <!-- 点击添加显示对话框 -->
      <el-button
        type="primary"
        size="small "
        icon="el-icon-plus"
        @click="isShowAdd = true"
        >新增</el-button
      >
      <el-button
        type="primary"
        size="small"
        icon="el-icon-refresh-right"
        @click="updatad"
        >刷新</el-button
      >
      <!-- 表格 -->
      <transition appear tag="div">
        <el-table
          ref="multipleTable"
          :data="tableData"
          stripe
          tooltip-effect="dark"
          style="width: 100%"
        >
          <el-table-column
            header-align="left"
            align="left"
            prop="id"
            label="字典编号"
            width="100"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="name"
            label="字典名称"
          ></el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="alias"
            label="字典类型"
          >
            <template slot-scope="scope">
              <span
                style="cursor: pointer; color: #2395f1"
                @click="typeClick(scope.row.id)"
                >{{ scope.row.alias }}</span
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="status"
            label="状态"
          >
            <template slot-scope="scope">
              <el-tag
                v-if="scope.row.status == 1"
                size="mini"
                type="success"
                effect="dark"
                >启用</el-tag
              >
              <el-tag v-else size="mini" type="danger" effect="dark"
                >禁用</el-tag
              >
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            prop="createTime"
            label="创建时间"
            sortable
            width="200"
          >
            <template slot-scope="scope">
              <span>{{ conversionTime(scope.row.createTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            header-align="left"
            align="left"
            label="操作"
            width="120"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                @click="isShoweditDialog(scope)"
                size="mini"
                icon="el-icon-edit"
                >编辑</el-button
              >
              <el-button
                type="text"
                @click="delClick(scope.row.id)"
                size="mini"
                icon="el-icon-delete"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </transition>
    </el-card>
    <!-- 添加用户对话框 -->
    <el-dialog
      title="添加字典"
      :visible.sync="isShowAdd"
      @close="addClose"
      width="35%"
      v-el-drag-dialog
    >
      <el-form
        :model="addForm"
        :rules="formRules"
        ref="addRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="字典名称" prop="name">
          <el-input
            v-model="addForm.name"
            placeholder="请输入字典名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="字典类型" prop="alias">
          <el-input
            v-model="addForm.alias"
            placeholder="请输入字典类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="字典状态" prop="status">
          <el-radio v-model="addForm.status" :label="1">启用</el-radio>
          <el-radio v-model="addForm.status" :label="0">禁用</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowAdd = false">取 消</el-button>
        <el-button type="primary" @click="addClick">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 编辑用户对话框 -->
    <el-dialog
      title="编辑字典"
      :visible.sync="isShowEdit"
      width="35%"
      v-el-drag-dialog
    >
      <el-form
        :model="editForm"
        :rules="formRules"
        ref="editRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="字典名称" prop="name">
          <el-input
            v-model="editForm.name"
            placeholder="请输入字典名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="字典类型" prop="alias">
          <el-input
            v-model="editForm.alias"
            placeholder="请输入字典类型"
          ></el-input>
        </el-form-item>
        <el-form-item label="字典状态" prop="status">
          <el-radio v-model="editForm.status" :label="1">启用</el-radio>
          <el-radio v-model="editForm.status" :label="0">禁用</el-radio>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="isShowEdit = false">取 消</el-button>
        <el-button type="primary" @click="editClick">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  sysDictType,
  addSysDictType,
  delSysDictType,
  flush,
} from '@/api/dictionaries.js'
export default {
  data() {
    return {
      isShowData: false,
      isShowAdd: false, //默认隐藏添加
      isShowEdit: false, // 默认隐藏编辑
      tableData: [], // 保存字典列表
      addForm: {
        // 添加表单的绑定数据
        name: '', // 名称
        alias: '', // 类型
        status: 1, // 状态
      },
      editForm: {
        // 编辑表单的绑定数据
        name: '', // 名称
        alias: '', // 类型
        status: 1, // 状态
      },
      dictionariesId: undefined, // 保存字典id
      formRules: {
        // 添加表单的验证规则
        name: [
          { required: true, message: '请输入字典名称', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
        alias: [
          { required: true, message: '请输入字典类型', trigger: 'blur' },
          {
            min: 1,
            max: 99,
            message: '长度在 1 到 99 个字符',
            trigger: 'blur',
          },
        ],
      },
    }
  },
  created() {
    this.sysDictType()
  },
  methods: {
    updatad() {
      flush().then((res) => {
        if (res.data.code === '200') {
          this.$message.success('刷新成功')
        } else if (res.data.code === '500') {
          this.$message.error('刷新失败')
        }
      })
    },
    sysDictType() {
      // 查询字典列表
      sysDictType().then((res) => {
        if (res.data.code === '200') {
          this.tableData = res.data.data
          if (this.tableData) {
            this.isShowData = true
          }
        }
      })
    },
    addClose() {
      // 监听添加用户对话框的关闭事件 清空input数据
      this.$refs.addRef.resetFields()
    },
    addClick() {
      // 添加字典
      this.$refs.addRef.validate((valid) => {
        if (!valid) return
        addSysDictType(this.addForm).then((res) => {
          if (res.data.code === '200') {
            this.isShowAdd = false
            this.$message.success('添加成功')
            this.sysDictType()
          }
        })
      })
    },
    isShoweditDialog(scope) {
      // 编辑显示弹框字典
      this.isShowEdit = true
      var value = JSON.parse(JSON.stringify(scope.row))
      this.editForm = value
    },
    editClick() {
      // 编辑字典
      addSysDictType(this.editForm).then((res) => {
        if (res.data.code === '200') {
          this.isShowEdit = false
          this.$message.success('编辑成功')
          this.sysDictType()
        }
      })
    },
    delClick(scope) {
      // 删除字典
      const id = scope
      this.$confirm('此操作将永久删除该文件, 是否继续?', '删除', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delSysDictType(id).then((res) => {
            if (res.data.code === '200') {
              this.$message.success('删除成功')
              this.sysDictType()
            }
          })
        })
        .catch(() => {
          this.$message.info('已取消删除')
        })
    },
    typeClick(scope) {
      // 传id并跳转查询字典类型页面
      this.$router.push({ path: 'dictionarytype', query: { id: scope } })
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
